<template>
  <div class="dados-bancarios  p-6">
    <div class="vx-row">
      <h3
        class="vx-col w-full font-semibold mb-4"
        style="padding: 0px; margin-top: -40px; padding-bottom: 30px;"
      >
        Dados Bancários
      </h3>
      <div
        v-if="dataList.length <= 0"
        class="flex flex-col w-full md:w-1/2 mx-auto items-center mt-5 mb-5"
      >
        <p><span class="material-icons text-6xl">description</span></p>
        <p class="text-center">
          Nenhum registro encontrado!
        </p>
      </div>
      <div
        class="vx-row list-account"
        :key="index"
        v-for="(item, index) in dataList"
        :style="item.Id != 0 ? '' : 'background: #fbfbfb'"
      >
        <el-tag v-if="index == 0" class="badge">Principal</el-tag>
        <div class="vx-col w-1/2">
          <template>
            <p>Selecione o banco <span v-if="isEssor">*</span></p>
            <el-select
              v-model="item.BankId"
              placeholder="Banco"
              filterable
              no-match-text="Sem resultado"
              no-data-text="Sem registro"
            >
              <el-option
                v-for="item in bancos"
                :key="item.value"
                :label="item.Name"
                :value="item.Id"
              >
              </el-option>
            </el-select>
          </template>
        </div>

        <div class="vx-col w-1/2">
          <p>Tipo da conta <span v-if="isEssor">*</span></p>
          <el-select
            v-model="item.BankAccountTypeId"
            placeholder="Selecione o tipo da conta"
            filterable
            no-match-text="Sem resultado"
            no-data-text="Sem registro"
          >
            <el-option
              v-for="item in accountTypeList"
              :key="item.value"
              :label="item.Name"
              :value="item.Id"
            >
            </el-option>
          </el-select>
        </div>

        <div class="vx-col md:w-1/4 w-1/2 mt-5">
          <p>Agência <span v-if="isEssor">*</span></p>
          <vs-input
            label=""
            size="large"
            v-model="item.Branch"
            v-mask="['###', '####', '#####', '######', '#######']"
            class="w-full"
          />
        </div>
        <div class="vx-col md:w-1/4 w-1/2 mt-5">
          <p>Dígito</p>
          <vs-input
            label=""
            size="large"
            v-mask="['#', '##']"
            v-model="item.DigitBranch"
            class="w-full"
          />
        </div>

        <div class="vx-col md:w-1/4 w-1/2 mt-5">
          <p>Conta <span v-if="isEssor">*</span></p>
          <vs-input
            label=""
            size="large"
            v-model="item.Number"
            v-mask="[
              '###',
              '####',
              '#####',
              '######',
              '#######',
              '####################'
            ]"
            class="w-full"
          />
        </div>
        <div class="vx-col md:w-1/4 w-1/2 mt-5">
          <p>Dígito <span v-if="isEssor">*</span></p>
          <vs-input
            label=""
            size="large"
            v-mask="['#']"
            v-model="item.DigitNumber"
            class="w-full"
          />
        </div>

        <div class="vx-col w-1/4 mt-5">
          <template>
            <p>Selecione o meio de pagamento <span v-if="isEssor">*</span></p>
            <el-select
              v-model="item.PaymentMethodId"
              placeholder="Meio de pagamento"
              filterable
              no-match-text="Sem resultado"
              no-data-text="Sem registro"
            >
              <el-option
                v-for="item in typesMethods"
                :key="item.PaymentMethodId"
                :label="item.PaymentMethodName"
                :value="item.PaymentMethodId"
              >
              </el-option>
            </el-select>
          </template>
        </div>
        <div class="vx-col md:w-1/4 w-1/2 mt-5 " style="position: relative;">
          <p>Conta Ativa ? <span v-if="isEssor">*</span></p>
          <vs-switch
            class="mr-3"
            v-model="item.IsActive"
            style="position: absolute; right: 12px; top: 33px; z-index: 9;"
          />
          <vs-input
            disabled
            label=""
            size="large"
            :style="item.IsActive ? 'color: green' : 'color: red'"
            :value="item.IsActive ? 'Sim' : 'Não'"
            class="w-full"
          />
        </div>
        <!-- <div class="vx-col md:w-1/4 w-1/2 mt-5" style="position: relative;">
          <vs-switch
            class="mr-3"
            v-model="item.IsActiveERP"
            style="position: absolute; right: 12px; top: 33px; z-index: 9;"
          />
          <vs-input
            disabled
            label="Conta ativa no ERP?"
            size="large"
            :value="item.IsActiveERP ? 'Sim' : 'Não'"
            class="w-full"
          />
        </div> -->
        <div class="vx-col text-right w-full mt-5">
          <vs-button
            @click="salvarDadosFinanceiro(index, item, true)"
            color="#bd0000"
            style="margin-right: 10px;"
          >
            {{ item.Id != 0 ? "Deletar" : "Remover" }}
          </vs-button>
          <vs-button
            @click="salvarDadosFinanceiro(index, item, false)"
            color="primary"
          >
            {{ item.Id != 0 ? "Atualizar" : "Salvar" }}
          </vs-button>
          <vs-button
            :disabled="item.Id == 0"
            style="margin-left: 10px;"
            v-if="mode === 'endosso'"
            @click="selecionarConta(item)"
            color="green"
          >
            Selecionar
          </vs-button>
        </div>
      </div>
      <div
        class="text-center  flex "
        style="justify-content: center; width: 100%;"
      >
        <el-button
          type="primary"
          round
          @click="addRow()"
          :disabled="!personIdEndorsement"
          :loading="!personIdEndorsement"
          icon='onpoint-plus'
          > Adicionar conta bancária</el-button
        >
      </div>
    </div>
    <!-- <div class="vx-row mt-10">
      <h3 class="vx-col w-full font-semibold">
        Dados Reponsável Financeiro
      </h3>

      <div class="vx-col md:w-1/2 w-full mt-5">
        <vs-input
          label="Nome Completo"
          size="large"
          v-model="dadosBancarios.FinancialOfficer.Name"
          class="w-full"
        />
      </div>

      <div class="vx-col md:w-1/2 w-full mt-5">
        <ValidationProvider name="cpf" rules="cpf" v-slot="{ errors }">
          <vs-input
            label="CPF"
            size="large"
            v-model="dadosBancarios.FinancialOfficer.CpfCnpj"
            v-mask="'###.###.###-##'"
            class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>

      <div class="vx-col md:w-1/2 w-full mt-5">
        <ValidationProvider name="email" rules="email" v-slot="{ errors }">
          <vs-input
            label="E-mail"
            type="email"
            size="large"
            class="w-full"
            v-model="dadosBancarios.FinancialOfficer.Email"
          />
          <span class="text-danger text-sm" v-show="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>

      <div class="vx-col md:w-1/2 w-full mt-5">
        <vs-input
          label="Telefone para contato"
          size="large"
          v-model="dadosBancarios.FinancialOfficer.PhoneNumber"
          v-mask="['(##) ####-####', '(##) #####-####']"
          class="w-full"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
import instance from "@/axios";
import { mask } from "vue-the-mask";
import PerfilHelpers from "@/helpers/PerfilHelpers";
import { integer } from "vee-validate/dist/rules";
import environment from "@/environment";

export default {
  name: "dados-bancarios",
  props: {
    personId: {
      type: Number,
      required: true
    },
    personUniqueId: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  directives: { mask },
  data() {
    return {
      personIdEndorsement: false,
      visible: false,
      bancos: [],
      editarBanco: false,
      accountTypeList: [],
      dataList: [],
      dadosBancarios: {
        BankId: null,
        BankBranchNumber: "",
        BankBranchDigit: "",
        BankAccountNumber: "",
        BankAccountDigit: "",
        BankAccountId: "",
        FinancialOfficer: {
          Name: "",
          Email: "",
          CpfCnpj: "",
          PhoneNumber: "",
          Id: 0
        }
      },
      typesMethods: false,
      perfilHelpers: PerfilHelpers,
      isEssor: false
    };
  },
  watch: {
    cadastro(value) {
      if (!!Object.keys(value).length)
        this.dadosBancarios = this.$utils.deepClone(value);
    }
  },
  methods: {
    selecionarConta(conta) {
      this.$emit("callSelectedBank", conta, false);
    },
    addRow() {
      let Object = {
        Id: 0,
        PersonId: this.personIdEndorsement.Id,
        BankId: null,
        BankAccountTypeId: null,
        Branch: "",
        DigitBranch: "",
        Number: "",
        DigitNumber: "",
        PaymentMethodId: null,
        IsActiveERP: true,
        IsActive: true,
        IsDeleted: false
      };
      this.dataList.push(Object);
    },
    getData() {
      this.$vs.loading();
      return instance
        .get(
          `/api/Core/GetPersonBankAccounts?personUniqueId=${this.personUniqueId}&lastBankAccount=false`
        )
        .then(response => {
          this.dataList = response.data.Response;
          this.$emit("callSelectedBank", response.data.Response[0], true);
          this.$vs.loading.close();
          window.scrollTo(0, 0);
        });
    },
    setBanco(value, index) {
      console.log(value, index);
      this.dataList[value].BankId = value;
    },
    setTipo(value) {
      this.dadosBancarios.BankAccountTypeId = value;
    },
    getListaBancos() {
      if (!this.bancos || !this.bancos.length)
        return instance.get("/api/Core/GetBanks").then(response => {
          this.bancos = response.data.Response;
          return this.bancos;
        });
    },
    getListaTiposPagamentos() {
      return instance.get("/api/Core/GetPaymentMethod ").then(response => {
        this.typesMethods = response.data.Response;
        return this.bancos;
      });
    },
    getListaTipoDeContas() {
      if (!this.accountTypeList || !this.accountTypeList.length)
        return instance.get("/api/Core/GetBankAccountTypes").then(response => {
          this.accountTypeList = response.data.Response;
          return this.accountTypeList;
        });
    },

    salvarDadosFinanceiro(index, conta, isDeleted) {
      // console.log(index);
      let dados = conta;
      if (isDeleted) {
        if (dados.Id == 0) {
          this.dataList.splice(index, 1);
          return;
        }
        dados.IsDeleted = true;
      } else {
        let error = false;
        Object.entries(conta).forEach(([key, value]) => {
          if (this.isEssor) {
            if (
              (value === "" || value === null) &&
              key !== "Ref" &&
              key !== "DigitBranch"
            ) {
              error = true;
              console.log(key);
            }
          } else {
            if ((value === "" || value === null) && key !== "Ref") {
              error = true;
              console.log(key);
            }
          }
        });
        if (error) {
          if (this.isEssor) {
            this.$onpoint.errorModal("Todos os valores com * são obrigatórios");
            return;
          } else {
            this.$onpoint.errorModal("Todos os valores são obrigatórios");
            return;
          }
        }
      }
      this.$vs.loading();
      return instance({
        method: "post",
        url: `/api/Core/SaveBankAccount`,
        data: dados
      })
        .then(() => {
          this.$notify({
            title: "Sucesso",
            message: "Dados atualizados com sucesso!",
            type: "success"
          });

          this.getData();
          this.$vs.loading.close();
        })
        .catch(errors => {
          this.$vs.loading.close();
          this.$onpoint.errorModal(errors.response.data.Errors);
        });
    }
  },
  mounted() {
    this.getListaTiposPagamentos();
    this.getData();
    this.getListaBancos();
    this.getListaTipoDeContas();
    this.isEssor =
      environment.CompanyId.Essor ==
      this.$store.state["auth"].userInfo.InsuranceId;
    // console.log("Buscando dados bancários", this.personId);
    console.log(this.isEssor);
      this.$vs.loading();
    return instance
      .get(`/api/PolicyHolder/GetPolicyHolder?UniqueId=${this.personUniqueId}`)
      .then(response => {
        this.personIdEndorsement = response.data.Response;
        this.$vs.loading.close();
      })
      .catch(errors => {
        this.$onpoint.errorModal(errors.response.data.Errors);
        this.$vs.loading.close();
      });
  }
};
</script>

<style lang="scss">
html {
  scroll-behavior: smooth !important;
  transition-timing-function: ease-in-out;
  transition-duration: 3s;
}
.disabled {
  background-color: rgba(239, 239, 239, 0.3);
}

h3 {
  color: rgba(var(--vs-primary), 1);
}
.dados-bancarios .active {
  background: #f3fff2;
}
.list-account {
  border-bottom: 1px solid lightgrey;
  padding-bottom: 20px;
  padding-top: 10px;
  margin-bottom: 25px;
  position: relative;
  .badge {
    position: absolute;
    top: -30px;
    left: 10px;
    background-color: rgba(var(--vs-primary), 1) !important;
    border-color: rgba(var(--vs-primary), 1) !important;
    color: white;
    font-weight: bold;
  }
}
</style>
